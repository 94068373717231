import { defineNuxtRouteMiddleware } from 'nuxt/app';
import { storeToRefs } from 'pinia';
import type { RouteLocationNormalized } from 'vue-router';

import { TERMS_UPDATE_PAGE_NAME } from '@/constants/url.const';
import { useTermsStore } from '@/stores/terms.store';
import { redirectTo } from '@/utils/common.util';

export default defineNuxtRouteMiddleware(async (_to: RouteLocationNormalized) => {
  const termsStore = useTermsStore();
  const { isNotAgreeAllTerms } = storeToRefs(termsStore);
  if (isNotAgreeAllTerms.value) {
    return await redirectTo(TERMS_UPDATE_PAGE_NAME, { toType: 'name' });
  }
});
